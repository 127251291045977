<template>
    <v-container v-if="process_id && machineOptions && machineOptions.length>0" fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-card :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroun2ColorCode}" flat outlined class="pa-3">    
            <v-form  :dark="$store.getters.getColorPalette().isDark"  ref="processForm" v-model="valid">
                <v-row>
                    <v-col>
                        <v-autocomplete
                            :rules=[rules.required]
                            v-if="!loading"
                            v-model="machine"
                            :items="machineOptions"
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                            outlined
                            dense
                            small-chips
                            :label="$store.getters.getTextMap().machines"
                            item-text="label"
                            item-value="value"
                        ></v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.process_status"
                            outlined
                            :items="processStatusOptions"
                            :label="$store.getters.getTextMap().process_status"
                            dense
                            filled
                            :rules="[rules.required]"
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col> 
                    <v-col>
                        <v-select
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.tag_type"
                            outlined
                            :items="tagTypeOptions"
                            :label="$store.getters.getTextMap().tag_type"
                            dense
                            filled
                            :rules="[rules.required]"
                            item-text="label"
                            item-value="value"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col> 
                </v-row>
                <v-row>
                    <v-col>
                        <v-select  
                            :dark="$store.getters.getColorPalette().isDark" 
                            :color="$store.getters.getColorPalette().inputtextColor"
                            v-if="!loading"
                            v-model="form.operator"
                            :items="operators"
                            :label="$store.getters.getTextMap().operator"
                            outlined
                            dense
                            filled
                            item-text="label"
                            item-value="value"
                            required
                            :rules="[rules.required]"
                            :menu-props="{dark: $store.getters.getColorPalette().isDark}"
                        ></v-select>
                    </v-col>
                    <v-col>
                        <v-text-field
                            dense
                            outlined
                            :color="$store.getters.getColorPalette().inputtext2Color"
                            :loading="loading"
                            v-model="form.operand"
                            :counter="8"
                            :rules="[rules.num]"
                            :label="$store.getters.getTextMap().operand"
                            required
                            ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col align="right">
                        <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
                        <v-btn class="mr-1" small v-if="!loading" :color="$store.getters.getColorPalette().submitbtnColor" @click="onSubmit">Submit</v-btn>
                        <v-btn v-if="!loading" small :color="$store.getters.getColorPalette().cancelbtnColor" :dark="$store.getters.getColorPalette().isDark" @click="onReset">Cancel</v-btn>
                    </v-col>
                </v-row>
            </v-form>
        </v-card>
        <v-row>
            <v-col>
                <ProcessStatusRuleList :machineList="machineOptions" :success="success"/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ProcessStatusRuleList from '@/components/lists/ProcessStatusRuleList'
import InfoAlert from '@/components/InfoAlert'
import FormRules from  '@/utillities/FormRules'
import axios from 'axios'
export default {
    name:'CreateProcessStatusRule',
    components:{
        InfoAlert,
        ProcessStatusRuleList
    },
    data(){
        return {
            showDismissibleAlert:false,
            success:false,
            loading:false,
            info:'',
            valid:false,
            api:"createProcessStatusRule",
            form:{},
            machineOptions:[],
            machine:null,
            process_id:null,
            processStatusOptions:[
                {label:this.$store.getters.getTextMap().running,value:'running'},
                {label:this.$store.getters.getTextMap().idling,value:'idling'},
                {label:this.$store.getters.getTextMap().offline,value:'offline'}
            ],
            operators:[
                {label:this.$store.getters.getTextMap().greater_than, value:'>'},
                {label:this.$store.getters.getTextMap().modulus_greater_than, value:'|>'},
                {label:this.$store.getters.getTextMap().lesser_than, value:'<'},
                {label:this.$store.getters.getTextMap().modulus_lesser_than, value:'|<'},
                {label:this.$store.getters.getTextMap().equal_to, value:'=='},
                {label:this.$store.getters.getTextMap().not_equal_to, value:'!='},
                {label:this.$store.getters.getTextMap().modulus_equal_to, value:'|=='},
                {label:this.$store.getters.getTextMap().greater_than_equal_to, value:'>='},
                {label:this.$store.getters.getTextMap().modulus_greater_than_equal_to, value:'|>='},
                {label:this.$store.getters.getTextMap().lesser_than_equal_to, value:'<='},
                {label:this.$store.getters.getTextMap().modulus_lesser_than_equal_to, value:'|<='},
                {label:this.$store.getters.getTextMap().bit_high, value:'bitHigh'},
                {label:this.$store.getters.getTextMap().bit_low, value:'bitLow'},
            ],
            rules:FormRules.rules,
        }
    },
    mounted(){
        this.process_id=this.$route.params.id
    },
    computed:{
        tagTypeOptions(){
            let op=[]
            if(this.$store.state.tagTypes && this.$store.state.tagTypes.length>0 ){
                for(let i of this.$store.state.tagTypes){
                    if(i && i.name){
                        op.push({value:i.tag_type, label: i.name })
                    }else{
                        op.push({value:i.tag_type, label: i.tag_type })
                    }
                }
            }
            return op
        },
    },
    methods:{
        getMachineList(){
            let op=[]
            if(this.process_id){
                let payload={
                    process_id:this.process_id
                }
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesByProcess", payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success") {
                        let machines=response.data.machines
                        for(let i of machines){
                            if(i && i.name){
                                op.push({value:{machine_id:i.machine_id,machine_name:i.name,process_id:this.process_id}, label: i.name })
                            }else{
                                op.push({value:i.machine_id, label: i.machine_id })
                            }
                        }
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    console.log(error)
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
            this.machineOptions=op
        },
        onSubmit(){
            this.$refs.processForm.validate()
            if(this.valid){
                this.loading = true;
                this.form['process_id']=this.process_id
                this.form['machine_id']=this.machine.machine_id
                axios.post(this.$store.state.api + this.api, this.form,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success") {
                        this.loading = false;
                        this.success=true
                        this.onReset()
                    } else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.onReset()
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
        },
        onReset(){
            this.$refs.processForm.reset()
            this.$emit('close')
        }
    },
    watch:{
        process_id(){
            this.getMachineList()
        },
        '$route'() {
            this.process_id = this.$route.params.id
            this.getMachineList()
        },
    }
}
</script>