<template>
    <v-container :style="[{'background-color': $store.getters.getColorPalette().backgroundColorCode}]" fluid>
    <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-row>
            <v-spacer></v-spacer>
            <v-col v-if="!$route.path.includes('workspace')" cols="auto" class="d-flex" align="right">
                <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  @click="getMachines">
                   {{ $store.getters.getTextMap().refresh }}<v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" @click="getMachines">mdi-cached</v-icon>
                </v-btn>
                <CreateProcessStatusRuleModal v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) " class="ml-1"/>  
                <EditProcessBottomSheet v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) " class="mx-1"  :process="process_id"/>
                <DeleteConfirmation v-if="$store.state.user && $store.state.settingMode && $store.getters.getAccessByLevels(['engineeraccess']) " v-on:confirm="deleteProcess(process_id)"  title="Delete Confirmation"  description="Are you sure you want to delete this?">
                      <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined> {{ $store.getters.getTextMap().delete_process }}<v-icon :color="$store.getters.getColorPalette().alertColor" >mdi-delete</v-icon></v-btn>
                </DeleteConfirmation>
            </v-col>
        </v-row>
        <v-row v-if="!loading">
            <v-col cols="4" v-for="machine in machines" :key="machine.machine_id" ><Machine :machine="machine" v-on:machineUpdate="getMachines"/></v-col>
        </v-row>
        <v-row v-else align="center">
            <v-col align="center" cols="12"><v-progress-circular indeterminate v-if="loading" color="primary"></v-progress-circular></v-col>
        </v-row>
        <v-row v-if="machines && machines.length<1">
            <v-col align="center">
              <v-icon large :dark="$store.getters.getColorPalette().isDark">mdi-hail</v-icon>
              <h3 :style="{'color':$store.getters.getColorPalette().accentCode}"> {{ $store.getters.getTextMap().no_machines_configured }}</h3></v-col>
        </v-row>
        <br>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import Machine from '@/components/items/Machine'
import CreateProcessStatusRuleModal from '@/components/modals/CreateProcessStatusRuleModal'
import EditProcessBottomSheet from '@/components/modals/EditProcessBottomSheet'
import DeleteConfirmation from '@/components/DeleteConfirmation'
// import EntureMachines from '@/views/Machines'
export default {
    name:'MachinePage',
    components:{
        InfoAlert,
        Machine,
        CreateProcessStatusRuleModal,
        EditProcessBottomSheet,
        DeleteConfirmation
    },
    mounted(){
        if( this.$route.path.includes('workspace')){
            this.workspace_id=this.$route.params.id
        }else{
            this.process_id=this.$route.params.id
        }

        this.getMachines()
    },
    data(){
        return {
            info:'',
            loading:false,
            showDismissibleAlert:false,
            machines:[],
            workspace_id:'',
            process_id:null,
            process: null
        }
    },
    methods:{
        deleteProcess(process){
    this.loading = true;
      axios.post(this.$store.state.api + "deleteProcess", {process_id:process},{headers: {
            Authorization: 'Bearer '+ this.$store.state.jwt
            }})
                .then(response => {
                if (response.data.status == "success") {
                    
                    
                    //console.log(response.data);
                    
                    this.$store.dispatch('refreshProcesses')
                //this.loading = false;
                    
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                //this.onReset()
                this.loading = false;
                })
                .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
                });
   },
        getMachines(){
            if(this.process_id){
                let payload={process_id:this.process_id}
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesByProcess", payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                    if (response.data.status == "success") {
                        this.machines=response.data.machines
                        this.machines= this.machines.sort((a, b) => a.process_seq - b.process_seq)
                    }else {
                        this.info = response.data.msg;
                        this.showDismissibleAlert = true;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }else if(this.workspace_id){
                let payload={
                    workspace_id:this.workspace_id
                }
                this.loading = true;
                axios.post(this.$store.state.api + "getMachinesByWorkspace", payload,{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
                .then(response => {
                if (response.data.status == "success") {
                    this.machines=response.data.machines
                    this.machines= this.machines.sort((a, b) => a.process_seq - b.process_seq)
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                    this.loading = false;
                })
                .catch(error => {
                    this.info = error;
                    this.showDismissibleAlert = true;
                    this.loading = false;
                });
            }
        },
    },
    watch:{
        workspace_id(){
            this.getMachines()
        },
        process_id(){
            this.getMachines()
        },
        '$route'() {
            if( this.$route.path.includes('workspace')){
                this.workspace_id = this.$route.params.id
            }else{
                this.process_id = this.$route.params.id
                this.getMachines()
            }
        },
    }
}
</script>