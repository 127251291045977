<template>
    <v-container fluid>
        <InfoAlert :showDismissibleAlert="showDismissibleAlert" :info="info" v-on:close="showDismissibleAlert=false" />
        <v-card class="my-1" v-if="showDelete" :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroun2ColorCode}" flat outlined> 
            <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                <v-card-title>{{'Delete Rules for '+deleteDetails.status}}</v-card-title>
                <v-row class="mx-1">
                    <v-col align="right">
                        <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="showDelete = false"><v-icon>mdi-close</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-toolbar>
            <v-row>
                <v-col>
                    <DeleteProcessStatusRules :machineList="machineList" :ruleDetails="deleteDetails" :process="process_id" v-on:delete="onDelete"/>
                </v-col>
            </v-row>
        </v-card>   
        <v-card v-if="!showDelete" :dark="$store.getters.getColorPalette().isDark"  :style="{'border': '1px solid '+ $store.getters.getColorPalette().accentCode, 'background-color':$store.getters.getColorPalette().backgroun2ColorCode}" flat outlined>    
            <v-row>
                <v-col>
                    <v-simple-table class="pb-5" :style="{background: 'transparent'}" >
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        <strong>{{$store.getters.getTextMap().status}}</strong>
                                    </th>
                                    <th class="text-left">
                                        <strong>{{$store.getters.getTextMap().rules}}</strong>
                                    </th>
                                    <th class="text-left">
                                        <strong>{{$store.getters.getTextMap().action}}</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr :key="item.process_status" v-for="item in tableData">
                                    <td>{{ item.process_status }}</td>
                                    <td>{{ item.rules }}</td>
                                    <td> <v-icon @click="deleteRule(item)" small  :color="$store.getters.getColorPalette().deletebtnColor">mdi-delete</v-icon></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
import axios from 'axios'
import InfoAlert from '@/components/InfoAlert'
import DeleteProcessStatusRules from '@/components/crud_components/DeleteProcessStatusRules'
export default {
    name:'ProcessStatusRuleList',
    props:['machineList','success'],
    components:{
        InfoAlert,
        DeleteProcessStatusRules
    },
    data(){
        return {
            showDismissibleAlert:false,
            loading:false,
            info:'',
            rawData:[],
            tableData:[],
            showDelete:false,
            deleteDetails:{},
        }
    },
    mounted(){
        this.process_id=this.$route.params.id
        this.fetchProcessStatusRules()
    },
    methods:{
        getMachineNameById(id){
            let op=''
            if(id ){
                for(let i of this.machineList){
                    if(i.value.machine_id==id){
                        op=i.label
                    }
                }
            }
            return op
        },
        createTable(){
            let temp={}
            this.tableData=[]
            for (let i of this.rawData) {
                if (!temp[i.process_status]) {
                    temp[i.process_status] = `${this.getMachineNameById(i.machine_id)} ${i.tag_type}${i.operator}${i.operand}`;
                } else {
                    temp[i.process_status] += ` and ${this.getMachineNameById(i.machine_id)} ${i.tag_type}${i.operator}${i.operand}`;
                }
            }
            for (let status in temp) {
                this.tableData.push({
                    process_status: status,
                    rules: temp[status]
                });
            }
        },
        fetchProcessStatusRules(){
            axios.post(this.$store.state.api + "getProcessStatusRulesByProcessId", {process_id:this.process_id},{headers: {Authorization: 'Bearer '+ this.$store.state.jwt}})
            .then(response => {
                if (response.data.status == "success") {
                    this.loading = false;
                    this.rawData=response.data.rules
                } else {
                    this.info = response.data.msg;
                    this.showDismissibleAlert = true;
                }
                this.loading = false;
            })
            .catch(error => {
                this.info = error;
                this.showDismissibleAlert = true;
                this.loading = false;
            });
        },
        deleteRule(item){
            this.deleteDetails['status']=item.process_status
            this.deleteDetails['rules']=this.rawData
            this.showDelete=true
        },
        onDelete(){
            this.showDelete=false
            this.fetchProcessStatusRules()
        }
    },
    watch:{
        rawData(){
            this.createTable()
        },
        success(){
            this.fetchProcessStatusRules()
        },
        process_id(){
            this.fetchProcessStatusRules()
        },
        '$route'() {
            this.process_id = this.$route.params.id
            this.fetchProcessStatusRules()
        },
    }
}
</script>