<template>
  <span class="text-center">
    <v-btn  :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined  @click="dialog=!dialog" >{{ $store.getters.getTextMap().edit_process }}
   <v-icon right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().downloadbtnColor" small>mdi-pencil</v-icon>
      </v-btn>
         
      <v-bottom-sheet v-model="dialog">
        <v-sheet
      :dark="$store.getters.getColorPalette().isDark"
          class="text-center"
           :style="{background: $store.getters.getColorPalette().backgroundColorCode, color:$store.getters.getColorPalette().accentcode}"
        >
    <v-card  :dark="$store.getters.getColorPalette().isDark"  :color="$store.getters.getColorPalette().backgroundColorCode">
    <v-toolbar
       :dark="$store.getters.getColorPalette().isDark"
           :color="$store.getters.getColorPalette().foregroundColorCode"
        >
          <v-btn
            icon
            :dark="$store.getters.getColorPalette().isDark" 
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $store.getters.getTextMap().edit_process }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
             :dark="$store.getters.getColorPalette().isDark" 
              text
              @click="dialog = false"
            >
            {{ $store.getters.getTextMap().close }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <EditProcess :process="process" v-on:close="dialog=false" />
                </v-col>
            </v-row>
        </v-container>
    </v-card>
  </v-sheet>
      </v-bottom-sheet>
  </span>
  
</template>

<script>
import EditProcess from '@/components/crud_components/EditProcess'
 export default {
     name:'EditProcessBottomSheet',
     props:['process'],
     components:{
      EditProcess
     },
    //  mounted(){
    //   console.log(this.process,'pro');
    //  },
     data(){
         return {
             dialog:false,
             process_id:null,
         }
     }
 }
 </script>