<template>
    <span class="text-center">
      <v-btn :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().accentCode" small text outlined @click="dialog = !dialog">
     {{ $store.getters.getTextMap().status_rule }}<v-icon  right :isDark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().btnborderColorCode" small>mdi-list-status</v-icon>
      </v-btn>
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card :color="$store.getters.getColorPalette(). backgroundColorCode" :dark="$store.getters.getColorPalette().isDark">
                <v-toolbar :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().foregroundColorCode">
                    <v-btn icon :dark="$store.getters.getColorPalette().isDark" @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
                    <v-toolbar-title>     {{ $store.getters.getTextMap().create_process_status_rule }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn :dark="$store.getters.getColorPalette().isDark" text @click="dialog = false">close</v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <CreateProcessStatusRule :process="process" v-on:close="onClose"/>
            </v-card>
        </v-dialog>
    </span>
</template>
<script>
import CreateProcessStatusRule from '@/components/crud_components/CreateProcessStatusRule'
export default {
  name:'CreateProcessStatusRuleModal',
  props:['process'],
  components:{
    CreateProcessStatusRule
  },
  data(){
      return {
          dialog:false
      }
    },
    methods:{
      onClose(){
          this.$emit('close')
      }
  }
}
</script>